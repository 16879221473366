.App {
  /* background-color: #000000; */
}
.headerList {
  list-style-type: none;
}

.App-logo {
  width: 254px;
  height: 30px;
}

.App-logo {
  display: inline-block;
  padding-left: 1%;
  padding-top: 2%;
  /* width: 40%; */
  margin: 0 auto;
  background-repeat: no-repeat;
  height: 24px;
}

#headerLinks {
  display: inline-block;
  margin-left: 36%;
  padding-bottom: 2%;
}

#features {
  text-decoration: none;
  color: white;
  font-family: Roboto, sans-serif;
  font-weight: 400;
}

#download {
  text-decoration: none;
  color: white;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  border-style: solid;
  border-width: 1px;
  border-color: #fff;
  border-radius: 8px;
  background-color: transparent;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 13px;
  padding-right: 13px;
  margin-left: 4px;
}

.containerBody {
  background-color: white;
  width: 80%;
  background-repeat: no-repeat;
  margin: 0 auto;
  border-radius: 15px;
  padding: 5%;
  margin-bottom: 2%;
}

#heading,
#firstParagpraph {
  width: 100%;
  font-family: Roboto, sans-serif;
}

#heading {
  font-size: 4vh;
  line-height: 5vh;
}

#firstParagpraph {
  font-size: 16px;
  line-height: 24px;
}

.appStore {
  height: 40px;
  margin-top: 10px;
}
.googlePlay {
  height: 40px;
  margin-left: 5px;
  margin-top: 10px;
}

#firstWidget {
  /* display: inline-block; */
  padding-left: 5vh;
}

.firstGif {
  display: inline-block;
}

.secondGif {
  width: 45%;
  display: inline-block;
}

.thirdGif {
  width: 45%;
  display: inline-block;
}

#subscribe-heading {
  font-size: 4vh;
  line-height: 6vh;
  font-weight: 400;
  justify-content: center;
}

.SubscriptionContainer {
  background-color: white;
  background-repeat: no-repeat;
  margin: 0 auto;
  border-radius: 15px;
  padding: 5%;
  text-align: center;
  align-content: center;
}

#email {
  width: 460px;
  height: 64px;
  margin-bottom: 0px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  border-radius: 8px;
  background-color: #fff;
  font-family: Roboto, sans-serif;
  color: #503bff;
  font-size: 16px;
  text-align: center;
  display: block;
  margin-bottom: 4%;
}
#email:focus {
  border: 1px solid #0dc3f1;
  outline: none;
}

.submitButton {
  width: 460px;
  height: 49px;
  padding-right: 60px;
  padding-left: 60px;
  border-radius: 8px;
  background-color: #503bff;
  font-family: Roboto, sans-serif;
  color: #fff;
  font-weight: 700;
  display: block;
  border:none;
  -webkit-appearance: none;
}

/* #email-form{

width: 50%;
margin: 0 auto;

} */

#paragraph2 {
  font-size: 12px;
  line-height: 16px;
  font-family: Roboto, sans-serif;
}

.footer {
  padding-bottom: 5%;
  align-content: center;
  padding-top: 2%;
}

#footerText {
  color: white;
  font-size: 14px;
  width: 100%;
  display: grid;
  text-align: center;
}

.btnTest {
  background-color: black;
}

.consentButton {
  width: 100%;
  text-align: center;
}
